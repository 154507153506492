.programmen-section {
    width: 100%;
    height: 100vh;
    display: flex;
}

.programmen-logos-container {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    padding: 5vw 2vw;

}


.programmen-logo-container {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 150px;
    height: 150px;
    margin: 1vw;
}

.programmen-logo-container img {
    width: 100%;
    height: auto;
}

@media (max-width: 1155px) {
    .programmen-section {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }

    .programmen-logos-container {
        width: 100vw;
        height: fit-content;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-content: space-evenly;
        padding: 5vw 7vw 10vw 7vw;
    }

    .programmen-logo-container {
        margin: 10px;
    }
}

@media (max-width: 525px) {
    .programmen-logo-container {
        width: 35vw;
        height: 35vw;
        margin: 10px;
        border-radius: 12px;
    }
}