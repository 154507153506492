@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

*,
::after,
::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: calc(14px + .390625vw);
  font-family: 'Montserrat', sans-serif;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
}


h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.5rem;
}

div,
p, img {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  display: inline-block;
  position: relative;
  color:#758D97;
}

a::after {
  content: '';
  position: absolute;
  text-align: center;
  width: 98%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 2%;
  background-color: #758D97;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
}

a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

a:active {
  color: #758D97;
}

a:visited {
  color: #758D97;
}