.leistung-container {
    display: flex;
    justify-content: flex-start;
    background-color: #F5F5F5;
    border-radius: 12px;
    padding: 15px;
    min-width: 44%;
    height: fit-content;
    align-items: center;
    margin: 10px 15px;
}

.leistung-icon {
    height: 30px;
    width: 30px;
}

.leistung-title {
    padding-left: 6px;
    color: #293345;
}


@media (max-width: 1155px) {
    .leistung-title {
        padding-left: 20px;
    }
}

@media (max-width: 525px) {
    .leistung-container {
        width: 80%;
    }
}