.kunden-section {
    width: 100%;
    height: 100vh;
    display: flex;
}

.kunden-logos-container {
    width: 50vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    padding: 5vw 2vw;

}


.logo-container {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 125px;
    height: 125px;
    margin: 1vw;
    padding: 1vw;
    background-color: #F5F5F5;
    border-radius: 12px;
}

.logo-container img {
    width: 100%;
    height: auto;
}


@media (max-width: 1155px) {
    .kunden-section {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .kunden-logos-container {
        width: 100vw;
        height: fit-content;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        align-content: space-evenly;
        padding: 5vw 7vw 10vw 7vw;
    }

    .logo-container {
        margin: 10px;
        padding: 20px;
    }
}

@media (max-width: 525px) {
    .logo-container {
        width: 35vw;
        height: 35vw;
        margin: 10px;
        border-radius: 12px;
        padding: 20px;
    }
}