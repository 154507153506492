.zahlen-container {
    width: 100%;
    height: 100vh;
    background-color: #293345;
    color: aliceblue;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 6vw 15vw;
}

@media screen and (max-width: 768px) {
    .zahlen-container {
        flex-direction: column;
        height: auto;
        padding: 6vw 0;
    }
}