.attributions-page-name {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    margin: 20px 0;
}

.attributions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100vw;
    padding: 5px;
}

.attribution-container {
    width: 26vw;
    padding: 10px 0;
}

.attribution-image-container img {
    width: 100%;
    height: 100%;
}

@media (max-width: 525px) {
    .attribution-container {
        width: 100vw;
        padding: 0vw 2vw 5vw 2vw;
    }
}