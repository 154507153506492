.kontakt-section {
    width: 100%;
    height: 100vh;
    display: flex;
    color: #293345;
}

.kontakt-text-container {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    padding: 7vw 7vw 15vw 7vw;
    color: #293345;
}

.kontakt-category {
    width: auto;
    font-weight: 600;
    color: #293345;
}

.kontakt-headline {
    width: auto;
    font-size: calc(18px + .390625vw);
    font-weight: 700;
    color: #293345;
    margin-top: 2vw;
    margin-bottom: 10%;
    text-align: left;
}

.kontakt-list-container {
    display: flex;
    flex-direction: column;
    width: auto;
    color: #293345;
    text-align: left;
    list-style: none;
}

.kontakt-list-container li {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.kontakt-list-container li div{
    padding-left: 10px;
}


.kontakt-img-container {
    width: 50vw;
    height: 100vh;
}

.kontakt-img {
    width: 50vw;
    height: 100vh;
    object-fit: cover;
}

@media (max-width: 1155px) {
    .kontakt-section {
        flex-direction: column;
        justify-content: space-between;
    }
    
    .kontakt-text-container {
        width: 100%;
        height: 50vh;
        padding: 7vw 7vw 0vw 7vw;
        justify-content: space-evenly;
    }

    .kontakt-headline {
        margin-top: 5%;
        margin-bottom: 3%;
    }

    .kontakt-img-container {
        width: 100vw;
        height: 50vh;
        padding: 5vw;
    }

    .kontakt-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-width: 525px) {
    .kontakt-text-container {
        padding: 15vw 7vw 7vw 7vw;
        justify-content: space-between;
    }
}