.zahlblock-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-zahl {
    font-size: 5vw;
    font-weight: 600;
    padding: 1vw;
}

.horizontal-line {
    width: 40%;
    border-bottom: 2px solid aliceblue;
    border-radius: 5px;
}

.category {
    font-size: 2vw;
    font-weight: 600;
    padding: 1vw 0 0 0;
}

.description {
    font-size: 1.5vw;
}



@media screen and (max-width: 768px) {
    .zahlblock-container {
        width: 100%;
        margin: 10vw 0;
        padding: 8vw 4vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-zahl {
        font-size: 25vw;
        padding: 2vw;
    }

    .horizontal-line {
        width: 60vw;
        border-bottom: 2px solid aliceblue;
        border-radius: 5px;
        margin: auto;
    }

    .category {
        font-size: 7vw;
        padding: 3vw 0 2vw 0;
    }

    .description {
        font-size: 4vw;
    }
}